.wrapper {
	position: relative;
	overflow: hidden;
}

.container {
	padding: 100px 20px 200px;
}

.contentContainer {
	width: 60%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 30px;
	margin-bottom: 80px;
}

.heading {
	border-left: 8px solid var(--red);
	padding: 10px 0 10px 20px;
	margin-bottom: 10px;
}

.background {
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: -1;
	top: 0;
	left: 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: var(--red);
}

.background::before,
.background::after {
	content: "";
	position: absolute;
	height: 100%;
}

.background[data-bg="false"]::before,
.background[data-bg="false"]::after {
	background-color: var(--white);
}

.background[data-bg="true"]::before,
.background[data-bg="true"]::after {
	background-color: var(--light-grey);
}

.background::before {
	height: 250%;
	width: 100%;
	rotate: 40deg;
	top: -120%;
	left: -35%;
}

.background::after {
	height: 250%;
	width: 100%;
	rotate: 40deg;
	bottom: -100%;
	right: -80%;
}

@media screen and (max-width: 1024px) {
	.contentContainer {
		background-color: var(--white);
		width: 100%;
		padding: 40px;
	}

	.background::after,
	.background::before {
		display: none;
	}
}

@media screen and (max-width: 768px) {
	.container {
		padding: 60px 20px;
	}
}
